<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>Kas Keluar</h2>
            </CCardHeader>
            <CCardBody>
                <CRow>
                    <div class="col-sm-label">
                        <label class="form-label font-weight-bold">Regional</label>
                        <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                    </div>
                    <div class="col-sm-content">
                        <v-select id="Region" class="pb-3" :options="RegionData" v-model="Region" @input="gridChange()"/>
                        <label id="errorRegion" class="form-error pb-2" style="display: none; color: red;"></label>
                    </div>
                    <div class="col-sm-label">
                        <label class="form-label font-weight-bold">Year</label>
                        <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                    </div>
                    <div class="col-sm-content">
                        <date-picker
                            v-model="Year"
                            format="YYYY"
                            type="year"
                            placeholder="Select year"
                            value-type="YYYY"
                            style="width:100%"
                            @change = "gridChange"
                        ></date-picker>
                        <label id="errorYear" class="form-error pb-2" style="display: none; color: red;"></label>
                    </div>
                    <div class="col-sm-label">
                        <label class="form-label font-weight-bold">Month</label>
                        <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                    </div>
                    <div class="col-sm-content">
                        <date-picker
                            v-model="Month"
                            format="MMMM"
                            type="month"
                            placeholder="Select month"
                            value-type="M"
                            style="width:100%"
                            @change = "gridChange"
                        ></date-picker>
                        <label id="errorMonth" class="form-error pb-2" style="display: none; color: red;"></label>
                    </div>
                </CRow>
                <span v-html="statusButton"></span>

                <div class="col-xs-6 text-light pb-2">
                    <button id="add" class="btn btn-success"                   
                    @click="addClick()">
                        <i class="fa fa-plus"></i> Add
                    </button>
                </div>

                <cash-out-grid ref="grid" :key="gridReload" :deleteClick="deleteClick" :editClick="editClick" :statusClick="statusClick" :pdfClick="pdfClick"/>
                <cash-out-status-form ref="CashOutStatusForm" :reload="reload" />
                <cash-out-pdf ref="pdf" />
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import { globalfunc } from '../../../shared/GlobalFunction';
import datePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import '@progress/kendo-ui';
import '@progress/kendo-theme-default/dist/all.css';
import cashOutGrid from '../CashOut/Grid/CashOutGrid.vue';
import cashOutServices from './Script/CashOutServices';
import CashOutStatusForm from './Component/CashOutStatusForm.vue';
import { response } from '../../../infrastructure/constant/response';
import cashOutPDF from '../../../assets/template/CashOutPdfTemplate.vue';
import { sweet_alert } from '../../../infrastructure/constant/variable';

export default {
    name: 'CashOut',
    components: {
        'date-picker' : datePicker,
        'cash-out-grid': cashOutGrid,
        'cash-out-status-form': CashOutStatusForm,
        'cash-out-pdf' : cashOutPDF
    },
    async mounted () {
        var permission = this.$globalfunc.permissionModule('Kas Keluar');
        document.getElementById("add").disabled = permission.cperms ? false : true;
        
        //Setup Cookies
        var region = window.$cookies.get('CashOut-Region');
        this.RegionData = await cashOutServices.getRegionQuery();
        this.Region = region == "null" ? null : this.RegionData.find(c => c.value == region);

        this.Month = window.$cookies.get('CashOut-Month') == "null" ? null : window.$cookies.get('CashOut-Month');
        this.Year = window.$cookies.get('CashOut-Year') == "null" ? null : window.$cookies.get('CashOut-Year');
        this.Status = window.$cookies.get('CashOut-Status') == "null" ? null : window.$cookies.get('CashOut-Status');
        
        this.statusFilter();
        this.gridChange();
    },
    data(){
        return{
            gridReload: 0,
            statusButton: '',
            Year: null,
            Month: null,
            RegionData: [],
            Region: null,
            Status: null,
        }
    },
    methods: {
        gridChange(){
            var region = this.Region == null ? null : this.Region.value;
            this.$cookies.set('CashOut-Year', this.Year, this.$cookies.get('TokenExpireTime'));
            this.$cookies.set('CashOut-Month', this.Month, this.$cookies.get('TokenExpireTime'));
            this.$cookies.set('CashOut-Region', region, this.$cookies.get('TokenExpireTime'));
            this.$cookies.set('CashOut-Status', this.Status, this.$cookies.get('TokenExpireTime'));
            this.$refs.grid.changeGrid(this.Status, region, this.Month, this.Year);
        },
        addClick(){
            this.$router.push({ name: 'Cash Out Form', params: {  formtype:'Add', id:' ', view:'new' } })
        },
        editClick(id, view){
            const routeData = this.$router.resolve({name: 'Cash Out Form', params: { formtype:'Edit', id:id, view:view }});
            window.open(routeData.href, '_blank');
            // this.$router.push({ name: 'Cash Out Form', params: { formtype:'Edit', id:id, view:view } });
        },
        deleteClick(id){
            this.$swal(sweet_alert.delete).then((result) => {
                var variables = {
                    id : id
                }
                if (result.isConfirmed == true) {
                    cashOutServices.deleteQuery(variables).then(res =>{
                        this.reload();
                        this.$swal("Info", response.successDelete, "success");
                    })
                    .catch(e => {
                        var errorMessage = this.$globalfunc.ErrorExtractor(e)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            })
        },
        statusClick(data) {
            this.$refs.CashOutStatusForm.statusClick(data);
        },
        pdfClick(id) {
            // this.$swal(sweet_alert.print).then((result) => {
            //     if (result.isConfirmed == true) {
            //         this.$refs.pdf.generatePDF(id);
            //     }else if (result.isDenied) {
                    const routeData = this.$router.resolve({name: 'Cash Out Html Template', params: { id:id }});
                    window.open(routeData.href, '_blank');
            //     }
            // });
        },
        reload(){
            this.gridReload++;
            this.statusFilter();
        },
        async statusFilter() {
            // var countStatus = await globalfunc.countTotal(window.location.pathname);
            // this.statusButton = await this.$globalfunc.dynamicStatusButton('ddl_status_journal', countStatus);
            this.statusButton = await this.$globalfunc.dynamicStatusButton();
            
            var vue = this;
            this.$nextTick(function () {
                $("#btnAll").on('click', function() {
                    vue.Status = null;
                    vue.gridChange();
                    // vue.reload();
                });
                $("#btnNew").on('click', function() {
                    vue.Status = "New";
                    vue.gridChange();
                    // vue.$refs.grid.changeStatus('New');
                });
                $("#btnApproval1").on('click', function() {
                    vue.Status = "Approval 1";
                    vue.gridChange();
                    // vue.$refs.grid.changeStatus('Approval 1');
                });
                $("#btnApproved").on('click', function() {
                    vue.Status = "Approved";
                    vue.gridChange();
                    // vue.$refs.grid.changeStatus('Approved');
                });
                $("#btnRevisi").on('click', function() {
                    vue.Status = "Revisi";
                    vue.gridChange();
                    // vue.$refs.grid.changeStatus('Revisi');
                });
            })
        },
    }
}
</script>

<style scoped>
.col-sm-label, .col-sm-content, .col-sm-half {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
@media (min-width: 768px) {
    .col-sm-label, .col-sm-content, .col-sm-half {
        float: left;
    }
    .col-sm-label {
        width: 12%;
    }
    .col-sm-content {
        width: 21%;
    }
    .col-sm-half{
        width: 33%;
    }
    .input-number{
        text-align: right;
    }
}
>>> {
    --vs-controls-size: 0.7;
    --vs-actions-padding: 4px 2px 0 3px;
    --vs-border-color: #d8dbe0;
    --vs-selected-color: #768192;
    --vs-dropdown-option-color: #768192;
    --vs-selected-max-height: 10px;
}
</style>